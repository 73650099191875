<template>
  <div class="body_section">
    <profileHeader
      :showFilter="false"
      @changedGlobalclient="changedGlobalclient"
    />

    <div class="body_content">
      <div class="main_content">
        <!--   <div class="subheader">
        <div><input class="search" type="text" v-model="serch_title" @keyup="serchTitle()" placeholder="Search by business name" /></div>
          <div class="d-flex">
              <filingsFilters
            @applyFilter="applyFilters"
            @clearFilter="clearFilters"
          ></filingsFilters>
          </div>
      </div>-->

        <div class="deadlinedetails_header">
          <div class="deadline_profile">
            <figure>
              <img
                @error="setAltImg($event)"
                :src="checkProperty(filingData, 'clientDetails', 'logo')"
              />
            </figure>
            <div>
              <figcaption>
                {{ checkProperty(filingData, "clientDetails", "busName") }}
              </figcaption>
              <span>{{
                checkProperty(filingData, "stateDetails", "name")
              }}</span>
              <div v-if="!showingTaxWithDate">
                <p>
                  <label>Period</label>
                  <template
                    v-if="
                      checkProperty(filingData, 'filingStartDate') != '' &&
                        checkProperty(filingData, 'filingStartDate') != '--'
                    "
                  >
                    {{ filingData.filingStartDate | formatDate }} <em>-</em>
                    {{ filingData.filingEndDate | formatDate }}
                  </template>

                  <span
                    style="display:inline-block; margin-left:20px;    color: #3B7CFF; cursor:pointer"
                    @click="showDate()"
                    >Change</span
                  >
                </p>
                <p>
                  <label>Grossed Sales</label>
                  <template
                    v-if="
                      checkProperty(filingData, 'totalAmount') != '' &&
                        checkProperty(filingData, 'totalAmount') != '--'
                    "
                  >
                    ${{ filingData.totalAmount.toFixed(2) }}
                  </template>
                </p>
                <p>
                  <label>Taxed Sales</label>
                  <template
                    v-if="
                      checkProperty(filingData, 'taxedAmount') != '' &&
                        checkProperty(filingData, 'taxedAmount') != '--'
                    "
                  >
                    ${{ filingData.taxedAmount.toFixed(2) }}
                  </template>
                </p>
                <p>
                  <label>Tax</label>
                  <template
                    v-if="
                      checkProperty(filingData, 'amount') != '' &&
                        checkProperty(filingData, 'amount') != '--'
                    "
                  >
                    ${{ filingData.amount.toFixed(2) }}
                  </template>
                </p>
              </div>

              <div v-if="showingTaxWithDate">
                <p>
                  <label>Period</label>
                  <template
                    v-if="
                      checkProperty(selected_createdDateRange, 'startDate') !=
                        '' &&
                        checkProperty(selected_createdDateRange, 'endDate') !=
                          '--'
                    "
                  >
                    {{ selected_createdDateRange.startDate | formatDate }}
                    <em>-</em>
                    {{ selected_createdDateRange.endDate | formatDate }}
                  </template>

                  <span
                    style="display:inline-block; margin-left:20px;    color: #3B7CFF; cursor:pointer"
                    @click="showDate()"
                    >Change</span
                  >
                </p>
                <p>
                  <label>Grossed Sales</label>
                  <template
                    v-if="
                      checkProperty(otherTaxDetails, 'totalAmount') != '' &&
                        checkProperty(otherTaxDetails, 'totalAmount') != '--'
                    "
                  >
                    ${{ otherTaxDetails.totalAmount.toFixed(2) }}
                  </template>
                </p>

                <p>
                  <label>Taxed Sales</label>
                  <template
                    v-if="
                      checkProperty(otherTaxDetails, 'taxedAmount') != '' &&
                        checkProperty(otherTaxDetails, 'taxedAmount') != '--'
                    "
                  >
                    ${{ otherTaxDetails.taxedAmount.toFixed(2) }}
                  </template>
                </p>
                <p>
                  <label>Tax</label>
                  <template
                    v-if="
                      checkProperty(otherTaxDetails, 'amount') != '' &&
                        checkProperty(otherTaxDetails, 'amount') != '--'
                    "
                  >
                    ${{ otherTaxDetails.amount.toFixed(2) }}
                  </template>
                </p>
                <div class="d-flex align-items-center">
                  <span
                    class="primary_btn add_more mart15 marb15"
                    @click="generateTax()"
                    >Generate Filing</span
                  >
                  <a href="#" class="clean_link" @click="onClear()"
                    ><b-icon icon="arrow-clockwise"></b-icon>Clear</a
                  >
                </div>
              </div>
            </div>
          </div>
          <button
            @click="fileExport()"
            :disabled="!showingTaxWithDate && list.length <= 0"
            class="filters_btn export_btn"
          >
            Export
          </button>
        </div>

        <div class="list_table">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Grossed Sales</th>
                <th scope="col">Taxed Sales</th>
                <th scope="col">Tax</th>
                <th scope="col" class="w-180">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="!showingTaxWithDate && list.length > 0">
                <tr v-for="(item, index) in list" :key="index">
                  <td>
                    {{ checkProperty(item, "name") }}
                  </td>

                  <td>
                    <template
                      v-if="
                        checkProperty(item, 'totalAmount') != '' &&
                          checkProperty(item, 'totalAmount') != '--'
                      "
                    >
                      {{
                        checkProperty(item, "totalAmount").toFixed(2) | currency
                      }}
                    </template>
                  </td>
                  <td>
                    <template
                      v-if="
                        checkProperty(item, 'taxedAmount') != '' &&
                          checkProperty(item, 'taxedAmount') != '--'
                      "
                    >
                      {{
                        checkProperty(item, "taxedAmount").toFixed(2) | currency
                      }}
                    </template>

                    <!-- {{checkProperty(item ,'taxedAmount').toFixed(2) | currency }} -->
                  </td>
                  <td>
                    {{ checkProperty(item, "amount").toFixed(2) | currency }}
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a
                        style="cursor:pointer"
                        @click="onTransactionDetails(item)"
                      >
                        DETAILS
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
              <template
                v-if="
                  showingTaxWithDate && otherTaxDetails.taxWiseStats.length > 0
                "
              >
                <tr
                  v-for="(item, index) in otherTaxDetails.taxWiseStats"
                  :key="index"
                >
                  <td>
                    {{ checkProperty(item, "name") }}
                  </td>

                  <td>
                    <template
                      v-if="
                        checkProperty(item, 'totalAmount') != '' &&
                          checkProperty(item, 'totalAmount') != '--'
                      "
                    >
                      {{
                        checkProperty(item, "totalAmount").toFixed(2) | currency
                      }}
                    </template>
                  </td>
                  <td>
                    <template
                      v-if="
                        checkProperty(item, 'taxedAmount') != '' &&
                          checkProperty(item, 'taxedAmount') != '--'
                      "
                    >
                      {{
                        checkProperty(item, "taxedAmount").toFixed(2) | currency
                      }}
                    </template>

                    <!-- {{checkProperty(item ,'taxedAmount').toFixed(2) | currency }} -->
                  </td>
                  <td>
                    {{ checkProperty(item, "amount").toFixed(2) | currency }}
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <a
                        style="cursor:pointer"
                        @click="onTransactionDetails(item)"
                      >
                        DETAILS
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <template v-if="!showingTaxWithDate && list.length <= 0">
            <span class="no_data">No Data!</span>
          </template>
          <template
            v-if="
              showingTaxWithDate && otherTaxDetails.taxWiseStats.length <= 0
            "
          >
            <span class="no_data">No Data!</span>
          </template>
        </div>

        <!-- <div class="pagination_wrap" >
                <div v-if="list.length > 0">
                <div class="pagination_content">
                      <h6>Show Records </h6>
                      <multiselect
                      :multiple="false"
                      @input="changePerPage()"
                      placeholder="Page"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :hide-selected="true"
                      :preserve-search="true"
                      v-model="perPage"
                      :options="perPeges"
                      >
                      <template slot="selection" slot-scope="{ values, isOpen }">
                          <span
                          class="multiselect__selectcustom"
                          v-if="values.length && !isOpen"
                          >{{ values.length }} selected</span
                          >
                          <span
                          class="multiselect__selectcustom"
                          v-if="values.length && isOpen"
                          ></span>
                      </template>
                      </multiselect>
                  </div>
                </div>

                  <b-pagination v-if="list.length > 0"
                      v-model="page"
                      :total-rows="totalPages"
                      :per-page="perPage"
                      pills

                      ></b-pagination>

              </div>-->

        <b-modal
          ref="my-modal"
          size="sm"
          centered
          hide-footer
          hide-header
          content-class="choosedate_modal"
        >
          <div class="modal_popup profile_popup">
            <div>
              <div>
                <b-button
                  class="close"
                  variant="outline-danger"
                  block
                  @click="hideMe"
                  ><span aria-hidden="true">&times;</span></b-button
                >

                <h6>Choose Dates</h6>
                <div class="full-range">
                  <date-range-picker
                    placeholder="YYYY-MM-DD"
                    :maxDate="new Date()"
                    :autoApply="autoApply"
                    :opens="'right'"
                    :ranges="false"
                    v-model="selected_createdDateRange"
                  ></date-range-picker>
                </div>
                <span class="primary_btn" @click="applyDateTax()">Submit</span>
              </div>
            </div>
          </div>
        </b-modal>
      </div>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>

<script>
//import Multiselect from "vue-multiselect-inv";
//import filingsFilters from "@/views/filters/transactionsFilters.vue";

import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";
import moment from "moment";
import _ from "lodash";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "@/axios.js";

export default {
  name: "notes",
  methods: {
    fileExport() {
      let startDate = this.filingData.filingStartDate;
      let endDate = this.filingData.filingEndDate;
      if (this.showingTaxWithDate) {
        startDate = this.selected_createdDateRange.startDate;
        endDate = this.selected_createdDateRange.endDate;
      }
      let postData = {
        trxnStartDate: "",
        trxnEndDate: "",
        clientId: this.filingData.clientId,
        stateId: this.filingData.stateId,
      };
      if (startDate && startDate != null && endDate && endDate != null) {
        postData["trxnStartDate"] = moment(startDate).format("YYYY-MM-DD");
        postData["trxnEndDate"] = moment(endDate).format("YYYY-MM-DD");
      }
      let APIURL = axios.defaults["baseURL"];

      this.$store
        .dispatch("getExportFilePatch", {
          data: postData,
          path: "/filing/details-export",
        })
        .then((response) => {
          this.exportData = response;
          //alert(JSON.stringify(APIURL+this.exportData['path']));
          //window.location.href = APIURL+"viewfile?path="+this.exportData['path'];
          window.open(
            APIURL + "viewfile?path=" + this.exportData["path"],
            "_blank"
          );
          //viewfile?path=
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error",
            text: error.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    changedGlobalclient(cl) {
      if (
        cl != null &&
        _.has(cl, "_id") &&
        cl["_id"] != null &&
        cl["_id"] != "null"
      ) {
        this.filingId = cl["_id"];
        this.getList();
      }
    },
    getList() {
      //filingId

      let postData = {
        filingId: this.filingId,
        // getTaxStats:true,
        filters: this.filterData,

        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };
      postData["filters"]["title"] = this.serch_title;

      this.list = [];
      this.$store
        .dispatch("getList", { data: postData, path: "/filings/details" })
        .then((response) => {
          //alert(JSON.stringify(response));
          this.list = response.taxWiseStats;
          this.filingData = response;
          console.log("filingData", this.filingData);
          this.selected_createdDateRange.startDate = this.filingData.filingStartDate;
          this.selected_createdDateRange.endDate = this.filingData.filingEndDate;

          //alert(JSON.stringify(this.list));
          // this.totalPages  = response.totalCount;
        })
        .catch((err) => {
          this.log(err);
        });
    },
    changePerPage() {
      this.page = 1;
      this.getList();
    },
    applyFilters(filterData) {
      //this.filterData = filterData: { "title": "","clientIds":[] ,"statusIds": [], "stateIds": [], "createdOnRange": []},

      this.filterData.clientIds = filterData.clientIds;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;

      this.getList();
    },
    clearFilters() {
      this.serch_title = "";
      (this.filterData = {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
      }),
        this.getList();
    },

    serchTitle() {
      this.page = 1;
      this.getList();
    },
    sortMe(sort_key = "") {
      this.sortKey = { path: "createdOn", order: 1 };
      if (sort_key != "") {
        this.sortKeys[sort_key] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.sortKey = {};

        this.sortKey["path"] = sort_key;
        this.sortKey["order"] = this.sortKeys[sort_key] == 1 ? -1 : 1;

        this.getList();
      }
    },
    UpdateAdjustMentAmount(item) {
      if (_.has(item, "filedAmount") && _.has(item, "amount")) {
        return item["amount"] - item["filedAmount"];
      } else {
        return "0:00";
      }
    },
    hideMe() {
      this.$refs["my-modal"].hide();
    },

    onClear() {
      this.showingTaxWithDate = false;
      //this.selected_createdDateRange = { startDate: null, endDate: null };
      this.selected_createdDateRange.startDate = this.filingData.filingStartDate;
      this.selected_createdDateRange.endDate = this.filingData.filingEndDate;
    },

    showDate() {
      this.$refs["my-modal"].show();
    },
    tooglePicker() {},
    applyDateTax() {
      let params = {
        trxnStartDate: "",
        trxnEndDate: "",
        clientId: this.filingData.clientId,
        stateId: this.filingData.stateId,
      };
      if (
        _.has(this.selected_createdDateRange, "startDate") &&
        this.selected_createdDateRange["startDate"] &&
        this.selected_createdDateRange["startDate"] != null &&
        _.has(this.selected_createdDateRange, "endDate") &&
        this.selected_createdDateRange["endDate"] &&
        this.selected_createdDateRange["endDate"] != null
      ) {
        params["trxnStartDate"] = moment(
          this.selected_createdDateRange["startDate"]
        ).format("YYYY-MM-DD");
        params["trxnEndDate"] = moment(
          this.selected_createdDateRange["endDate"]
        ).format("YYYY-MM-DD");
      }
      this.$store
        .dispatch("getTaxWithDate", params)
        .then((res) => {
          this.$refs["my-modal"].hide();
          if (res) {
            this.showingTaxWithDate = true;
          }
          this.otherTaxDetails.amount = Number(res.amount);
          this.otherTaxDetails.taxedAmount = Number(res.taxedAmount);
          this.otherTaxDetails.totalAmount = Number(res.totalAmount);
          this.otherTaxDetails.taxWiseStats = res.taxWiseStats;
        })
        .catch((err) => {
          this.notify({ message: err, title: "Error", type: "error" });
        });
    },

    generateTax() {
      let params = {
        trxnStartDate: "",
        trxnEndDate: "",
        filedTrxnStartDate: "",
        filedTrxnEndDate: "",
        clientId: this.filingData.clientId,
        stateId: this.filingData.stateId,
        amount: this.otherTaxDetails.amount,
        taxedAmount: this.otherTaxDetails.taxedAmount,
        totalAmount: this.otherTaxDetails.totalAmount,
        taxWiseStats: this.otherTaxDetails.taxWiseStats,
      };
      if (
        _.has(this.selected_createdDateRange, "startDate") &&
        this.selected_createdDateRange["startDate"] &&
        this.selected_createdDateRange["startDate"] != null &&
        _.has(this.selected_createdDateRange, "endDate") &&
        this.selected_createdDateRange["endDate"] &&
        this.selected_createdDateRange["endDate"] != null
      ) {
        params["trxnStartDate"] = moment(
          this.selected_createdDateRange["startDate"]
        ).format("YYYY-MM-DD");
        params["trxnEndDate"] = moment(
          this.selected_createdDateRange["endDate"]
        ).format("YYYY-MM-DD");
      }
      if (
        _.has(this.filingData, "filingStartDate") &&
        this.filingData["filingStartDate"] &&
        this.filingData["filingStartDate"] != null &&
        _.has(this.filingData, "filingEndDate") &&
        this.filingData["filingEndDate"] &&
        this.filingData["filingEndDate"] != null
      ) {
        params["filedTrxnStartDate"] = moment(
          this.filingData["filingStartDate"]
        ).format("YYYY-MM-DD");
        params["filedTrxnEndDate"] = moment(
          this.filingData["filingEndDate"]
        ).format("YYYY-MM-DD");
      }
      this.$store
        .dispatch("generateTaxWithDate", params)
        .then((res) => {
          this.onClear();
          this.filingId = res.result["_id"];
          this.getList();
          this.notify({
            message: res.message,
            title: "Success",
            type: "success",
          });
          this.$router.push("/filing-details/" + res.result["_id"]);
        })
        .catch((err) => {
          this.notify({ message: err, title: "Error", type: "error" });
        });
    },

    onTransactionDetails(item) {
      this.$router.push({
        path: "/transactions",
        query: {
          filingStartDate: this.filingData.filingStartDate,
          filingEndDate: this.filingData.filingEndDate,
          stateId: this.filingData.stateId,
          clientId: this.filingData.clientId,
          taxName: item.name,
        },
      });
    },
  },
  data() {
    return {
      filingData: null,
      userdata: null,
      isLoaded: false,
      loginUser: null,
      page: 1,
      perPage: 25,
      totalPages: 0,
      list: [],
      sortKey: {},
      perPeges: [10, 25, 50, 75, 100],
      filterData: {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
      },
      serch_title: "",
      sortKeys: {
        stateName: 1,
        clientName: 1,
        createdOn: -1,
        dueDate: 1,
        amount: 1,
      },
      date: {
        from: null,
        to: null,
        month: null,
        year: null,
      },
      selected_createdDateRange: {},
      autoApply: "",
      showingTaxWithDate: false,
      otherTaxDetails: {
        amount: "",
        taxedAmount: "",
        totalAmount: "",
        taxWiseStats: [],
      },
    };
  },
  components: {
    // filingsFilters,
    // Multiselect
    profileHeader,
    footerView,
    // MonthPicker
    // MonthPickerInput
    DateRangePicker,
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    this.isLoaded = true;
    this.sortKey = { path: "createdOn", order: -1 };
    if (_.has(this.$route.params, "filingId")) {
      this.filingId = this.$route.params["filingId"];
      this.getList();
    }

    this.sortKeys = {
      stateName: 1,
      clientName: 1,
      createdOn: -1,
    };
  },

  watch: {
    page: function(val) {
      this.log(val);
      this.getList();
    },
  },
};
</script>

<style scoped>
.full-range {
  width: 100%;
  padding-bottom: 20px;
}
.full-range .vue-daterange-picker {
  width: 100%;
}
</style>
