var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body_section"},[_c('profileHeader',{attrs:{"showFilter":false},on:{"changedGlobalclient":_vm.changedGlobalclient}}),_c('div',{staticClass:"body_content"},[_c('div',{staticClass:"main_content"},[_c('div',{staticClass:"deadlinedetails_header"},[_c('div',{staticClass:"deadline_profile"},[_c('figure',[_c('img',{attrs:{"src":_vm.checkProperty(_vm.filingData, 'clientDetails', 'logo')},on:{"error":function($event){return _vm.setAltImg($event)}}})]),_c('div',[_c('figcaption',[_vm._v(" "+_vm._s(_vm.checkProperty(_vm.filingData, "clientDetails", "busName"))+" ")]),_c('span',[_vm._v(_vm._s(_vm.checkProperty(_vm.filingData, "stateDetails", "name")))]),(!_vm.showingTaxWithDate)?_c('div',[_c('p',[_c('label',[_vm._v("Period")]),(
                    _vm.checkProperty(_vm.filingData, 'filingStartDate') != '' &&
                      _vm.checkProperty(_vm.filingData, 'filingStartDate') != '--'
                  )?[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.filingData.filingStartDate))+" "),_c('em',[_vm._v("-")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.filingData.filingEndDate))+" ")]:_vm._e(),_c('span',{staticStyle:{"display":"inline-block","margin-left":"20px","color":"#3B7CFF","cursor":"pointer"},on:{"click":function($event){return _vm.showDate()}}},[_vm._v("Change")])],2),_c('p',[_c('label',[_vm._v("Grossed Sales")]),(
                    _vm.checkProperty(_vm.filingData, 'totalAmount') != '' &&
                      _vm.checkProperty(_vm.filingData, 'totalAmount') != '--'
                  )?[_vm._v(" $"+_vm._s(_vm.filingData.totalAmount.toFixed(2))+" ")]:_vm._e()],2),_c('p',[_c('label',[_vm._v("Taxed Sales")]),(
                    _vm.checkProperty(_vm.filingData, 'taxedAmount') != '' &&
                      _vm.checkProperty(_vm.filingData, 'taxedAmount') != '--'
                  )?[_vm._v(" $"+_vm._s(_vm.filingData.taxedAmount.toFixed(2))+" ")]:_vm._e()],2),_c('p',[_c('label',[_vm._v("Tax")]),(
                    _vm.checkProperty(_vm.filingData, 'amount') != '' &&
                      _vm.checkProperty(_vm.filingData, 'amount') != '--'
                  )?[_vm._v(" $"+_vm._s(_vm.filingData.amount.toFixed(2))+" ")]:_vm._e()],2)]):_vm._e(),(_vm.showingTaxWithDate)?_c('div',[_c('p',[_c('label',[_vm._v("Period")]),(
                    _vm.checkProperty(_vm.selected_createdDateRange, 'startDate') !=
                      '' &&
                      _vm.checkProperty(_vm.selected_createdDateRange, 'endDate') !=
                        '--'
                  )?[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.selected_createdDateRange.startDate))+" "),_c('em',[_vm._v("-")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.selected_createdDateRange.endDate))+" ")]:_vm._e(),_c('span',{staticStyle:{"display":"inline-block","margin-left":"20px","color":"#3B7CFF","cursor":"pointer"},on:{"click":function($event){return _vm.showDate()}}},[_vm._v("Change")])],2),_c('p',[_c('label',[_vm._v("Grossed Sales")]),(
                    _vm.checkProperty(_vm.otherTaxDetails, 'totalAmount') != '' &&
                      _vm.checkProperty(_vm.otherTaxDetails, 'totalAmount') != '--'
                  )?[_vm._v(" $"+_vm._s(_vm.otherTaxDetails.totalAmount.toFixed(2))+" ")]:_vm._e()],2),_c('p',[_c('label',[_vm._v("Taxed Sales")]),(
                    _vm.checkProperty(_vm.otherTaxDetails, 'taxedAmount') != '' &&
                      _vm.checkProperty(_vm.otherTaxDetails, 'taxedAmount') != '--'
                  )?[_vm._v(" $"+_vm._s(_vm.otherTaxDetails.taxedAmount.toFixed(2))+" ")]:_vm._e()],2),_c('p',[_c('label',[_vm._v("Tax")]),(
                    _vm.checkProperty(_vm.otherTaxDetails, 'amount') != '' &&
                      _vm.checkProperty(_vm.otherTaxDetails, 'amount') != '--'
                  )?[_vm._v(" $"+_vm._s(_vm.otherTaxDetails.amount.toFixed(2))+" ")]:_vm._e()],2),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"primary_btn add_more mart15 marb15",on:{"click":function($event){return _vm.generateTax()}}},[_vm._v("Generate Filing")]),_c('a',{staticClass:"clean_link",attrs:{"href":"#"},on:{"click":function($event){return _vm.onClear()}}},[_c('b-icon',{attrs:{"icon":"arrow-clockwise"}}),_vm._v("Clear")],1)])]):_vm._e()])]),_c('button',{staticClass:"filters_btn export_btn",attrs:{"disabled":!_vm.showingTaxWithDate && _vm.list.length <= 0},on:{"click":function($event){return _vm.fileExport()}}},[_vm._v(" Export ")])]),_c('div',{staticClass:"list_table"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',[(!_vm.showingTaxWithDate && _vm.list.length > 0)?_vm._l((_vm.list),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(_vm.checkProperty(item, "name"))+" ")]),_c('td',[(
                      _vm.checkProperty(item, 'totalAmount') != '' &&
                        _vm.checkProperty(item, 'totalAmount') != '--'
                    )?[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.checkProperty(item, "totalAmount").toFixed(2)))+" ")]:_vm._e()],2),_c('td',[(
                      _vm.checkProperty(item, 'taxedAmount') != '' &&
                        _vm.checkProperty(item, 'taxedAmount') != '--'
                    )?[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.checkProperty(item, "taxedAmount").toFixed(2)))+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.checkProperty(item, "amount").toFixed(2)))+" ")]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onTransactionDetails(item)}}},[_vm._v(" DETAILS ")])])])])}):_vm._e(),(
                _vm.showingTaxWithDate && _vm.otherTaxDetails.taxWiseStats.length > 0
              )?_vm._l((_vm.otherTaxDetails.taxWiseStats),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(_vm.checkProperty(item, "name"))+" ")]),_c('td',[(
                      _vm.checkProperty(item, 'totalAmount') != '' &&
                        _vm.checkProperty(item, 'totalAmount') != '--'
                    )?[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.checkProperty(item, "totalAmount").toFixed(2)))+" ")]:_vm._e()],2),_c('td',[(
                      _vm.checkProperty(item, 'taxedAmount') != '' &&
                        _vm.checkProperty(item, 'taxedAmount') != '--'
                    )?[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.checkProperty(item, "taxedAmount").toFixed(2)))+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.checkProperty(item, "amount").toFixed(2)))+" ")]),_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onTransactionDetails(item)}}},[_vm._v(" DETAILS ")])])])])}):_vm._e()],2)]),(!_vm.showingTaxWithDate && _vm.list.length <= 0)?[_c('span',{staticClass:"no_data"},[_vm._v("No Data!")])]:_vm._e(),(
            _vm.showingTaxWithDate && _vm.otherTaxDetails.taxWiseStats.length <= 0
          )?[_c('span',{staticClass:"no_data"},[_vm._v("No Data!")])]:_vm._e()],2),_c('b-modal',{ref:"my-modal",attrs:{"size":"sm","centered":"","hide-footer":"","hide-header":"","content-class":"choosedate_modal"}},[_c('div',{staticClass:"modal_popup profile_popup"},[_c('div',[_c('div',[_c('b-button',{staticClass:"close",attrs:{"variant":"outline-danger","block":""},on:{"click":_vm.hideMe}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('h6',[_vm._v("Choose Dates")]),_c('div',{staticClass:"full-range"},[_c('date-range-picker',{attrs:{"placeholder":"YYYY-MM-DD","maxDate":new Date(),"autoApply":_vm.autoApply,"opens":'right',"ranges":false},model:{value:(_vm.selected_createdDateRange),callback:function ($$v) {_vm.selected_createdDateRange=$$v},expression:"selected_createdDateRange"}})],1),_c('span',{staticClass:"primary_btn",on:{"click":function($event){return _vm.applyDateTax()}}},[_vm._v("Submit")])],1)])])])],1),_c('footerView',{ref:"rout_view"})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Grossed Sales")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Taxed Sales")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Tax")]),_c('th',{staticClass:"w-180",attrs:{"scope":"col"}},[_vm._v("Actions")])])])}]

export { render, staticRenderFns }